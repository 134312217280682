import React from 'react';

import Hero from 'components/hero';
import DefaultLayout from 'layouts/default';

const title = 'Your message has been sucessfully submitted';

const SuccessPage = () => (
  <DefaultLayout lang="en" title={title} url="/success" withFooter={false}>
    <Hero title={title} withBorder />
  </DefaultLayout>
);

export default SuccessPage;
